import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step certificate install`}</strong>{` -- install a root certificate in the system truststore`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step certificate install <crt-file>
[--prefix=<name>] [--all]
[--java] [--firefox] [--no-system]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step certificate install`}</strong>{` installs a root certificate in the system
truststore.`}</p>
    <p>{`Java and Firefox truststores are also supported via the respective flags.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`crt-file`}</inlineCode>{`
Certificate to install in the system truststore`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--prefix`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The prefix used to `}<inlineCode parentName="p">{`name`}</inlineCode>{` the CA in the truststore. Defaults to the
certificate common name.`}</p>
    <p><strong parentName="p">{`--java`}</strong>{`
install on the Java key store`}</p>
    <p><strong parentName="p">{`--firefox`}</strong>{`
install on the Firefox NSS security database`}</p>
    <p><strong parentName="p">{`--no-system`}</strong>{`
disables the install on the system truststore`}</p>
    <p><strong parentName="p">{`--all`}</strong>{`
install on the system, Firefox and Java truststores`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Install a certificate in the system truststore:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate install root-ca.pem
`}</code></pre>
    <p>{`Install a certificate in all the supported truststores:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate install --all root-ca.pem
`}</code></pre>
    <p>{`Install a certificate in Firefox and the system truststore:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate install --firefox root--ca.pem
`}</code></pre>
    <p>{`Install a certificate in Java and the system truststore:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate install --java root-ca.pem
`}</code></pre>
    <p>{`Install a certificate in Firefox, Java, but not in the system truststore:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate install --firefox --java --no-system root-ca.pem
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      